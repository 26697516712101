import React from 'react';

interface AppleMenuProps {
    onAboutMeClick: () => void;
    onLogout: () => void;
}

const AppleMenu: React.FC<AppleMenuProps> = ({ onAboutMeClick, onLogout }) => {
    return (
        <div id="apple-menu" className="absolute top-7 left-4 bg-white bg-opacity-40 shadow-lg rounded px-2 py-1 w-60 backdrop-blur-2xl">
            <div className="flex flex-col space-y-2">
                <button className="text-left text-sm p-1 pb-2 border-b border-b-[#979797] hover:bg-gray-200 " onClick={onAboutMeClick}>About Me</button>
                <button className="text-left text-sm p-1 hover:bg-gray-200 " onClick={onLogout}>Logout</button>
            </div>
        </div>
    );
};

export default AppleMenu;
