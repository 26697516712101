import { Technology } from "./data";
export const technologyData: Technology[] = [
    { id: 53, name: "Anaconda", category: "Software", icon: require("./assets/softwareIcons/Anaconda.svg").default },
    { id: 54, name: "Android Studio", category: "Software", icon: require("./assets/softwareIcons/Android-Studio.svg").default },
    { id: 7, name: "Adobe Creative Suite", category: "Software", icon: require("./assets/softwareIcons/adobe.svg").default },
    { id: 33, name: "AWS", category: "Service", icon: require("./assets/softwareIcons/AWS.svg").default },
    { id: 48, name: "AWS Lambda", category: "Service", icon: require("./assets/softwareIcons/AWS.svg").default },
    { id: 49, name: "AWS EC2", category: "Service", icon: require("./assets/softwareIcons/AWS.svg").default },
    { id: 50, name: "AWS S3", category: "Service", icon: require("./assets/softwareIcons/AWS.svg").default  },
    { id: 51, name: "AWS Amplify", category: "Service", icon: require("./assets/softwareIcons/AWS.svg").default },
    { id: 52, name: "AWS RDS", category: "Service", icon: require("./assets/softwareIcons/AWS.svg").default },
    { id: 53, name: "AWS DynamoDB", category: "Service", icon: require("./assets/softwareIcons/AWS.svg").default },
    { id: 38, name: "Angular", category: "Framework", icon: require("./assets/softwareIcons/AngularJS.svg").default },
    { id: 27, name: "Arduino IDE", category: "Software", icon: require("./assets/softwareIcons/Arduino.svg").default },
    { id: 1, name: "AutoCAD", category: "Software", icon: require("./assets/softwareIcons/autocad.svg").default },
    { id: 56, name: "Azure", category: "Service", icon: require("./assets/softwareIcons/Azure.svg").default },
    { id: 23, name: "Blender", category: "Software", icon: require("./assets/softwareIcons/Blender.svg").default },
    { id: 8, name: "C", category: "Programming Language", icon: require("./assets/softwareIcons/C.svg").default },
    { id: 9, name: "C++", category: "Programming Language", icon: require("./assets/softwareIcons/CPlusPlus.svg").default },
    { id: 57, name: "CMake", category: "Tool", icon: require("./assets/softwareIcons/CMake.svg").default },
    { id: 81, name: "CPLEX", category: "Software", icon: require("./assets/softwareIcons/CPLEX-logo.svg").default },
    { id: 58, name: "CSS3", category: "Tool", icon: require("./assets/softwareIcons/CSS3.svg").default },
    { id: 34, name: "Docker", category: "Tool", icon: require("./assets/softwareIcons/Docker.svg").default },
    { id: 39, name: "Electron", category: "Framework", icon: require("./assets/softwareIcons/Electron.svg").default },
    { id: 59, name: "Embedded C", category: "Programming Language", icon: require("./assets/softwareIcons/Embedded-C.svg").default },
    { id: 60, name: "Express", category: "Framework", icon: require("./assets/softwareIcons/Express.svg").default },
    { id: 25, name: "Figma", category: "Software", icon: require("./assets/softwareIcons/Figma.svg").default },
    { id: 4, name: "Fritzing", category: "Software", icon: require("./assets/softwareIcons/Fritzing.png") },
    { id: 61, name: "FileZilla", category: "Tool", icon: require("./assets/softwareIcons/FileZilla.svg").default },
    { id: 45, name: "Firebase", category: "Service", icon: require("./assets/softwareIcons/Firebase.svg").default },
    { id: 29, name: "FluidSIM", category: "Software", icon: require("./assets/softwareIcons/plc.svg").default },
    { id: 41, name: "Flutter", category: "Framework", icon: require("./assets/softwareIcons/Flutter.svg").default },
    { id: 17, name: "Function Block Diagrams", category: "Tool", icon: require("./assets/softwareIcons/plc.svg").default },
    { id: 14, name: "GRAPH", category: "Tool", icon: require("./assets/softwareIcons/plc.svg").default },
    { id: 24, name: "Git", category: "Tool", icon: require("./assets/softwareIcons/Git.svg").default },
    { id: 76, name: "GitHub", category: "Tool", icon: require("./assets/softwareIcons/GitHub.svg").default },
    { id: 62, name: "GraphQL", category: "Framework", icon: require("./assets/softwareIcons/GraphQL.svg").default },
    { id: 63, name: "HTML5", category: "Tool", icon: require("./assets/softwareIcons/HTML5.svg").default },
    { id: 19, name: "Instruction Lists", category: "Tool", icon: require("./assets/softwareIcons/plc.svg").default },
    { id: 2, name: "Inventor", category: "Software", icon: require("./assets/softwareIcons/inventor.jpg") },
    { id: 21, name: "IntelliJ", category: "Software", icon: require("./assets/softwareIcons/IntelliJ-IDEA.svg").default },
    { id: 5, name: "KiCad", category: "Software", icon: require("./assets/softwareIcons/kicad.png") },
    { id: 28, name: "KLayout", category: "Software", icon: require("./assets/softwareIcons/KLayout.png") },
    { id: 47, name: "Keras", category: "Framework", icon: require("./assets/softwareIcons/Keras.svg").default },
    {id: 80, name: "Java", category: "Programming Language", icon: require("./assets/softwareIcons/Java.svg").default},
    {id: 79, name: "JavaScript", category: "Programming Language", icon: require("./assets/softwareIcons/JavaScript.svg").default},
    { id: 65, name: "Jupyter", category: "Software", icon: require("./assets/softwareIcons/Jupyter.svg").default },
    { id: 26, name: "Jira", category: "Software", icon: require("./assets/softwareIcons/Jira.svg").default },
    { id: 64, name: "JSON", category: "Tool", icon: require("./assets/softwareIcons/JSON.svg").default },
    { id: 77, name: "jQuery", category: "Framework", icon: require("./assets/softwareIcons/jQuery.svg").default },
    { id: 66, name: "LabVIEW", category: "Software", icon: require("./assets/softwareIcons/LabVIEW.svg").default },
    { id: 15, name: "Ladder Logic", category: "Tool", icon: require("./assets/softwareIcons/plc.svg").default },
    { id: 31, name: "Matplotlib", category: "Framework", icon: require("./assets/softwareIcons/Matplotlib.svg").default },
    { id: 32, name: "Matlab", category: "Software", icon: require("./assets/softwareIcons/MATLAB.svg").default },
    { id: 6, name: "Microsoft Suite", category: "Software", icon: require("./assets/softwareIcons/microsoftOffice.svg").default },
    { id: 42, name: "MongoDB", category: "Tool", icon: require("./assets/softwareIcons/MongoDB.svg").default },
    { id: 35, name: "Node.js", category: "Framework", icon: require("./assets/softwareIcons/Nodejs.svg").default },
    { id: 30, name: "Numpy", category: "Framework", icon: require("./assets/softwareIcons/NumPy.svg").default },
    { id: 37, name: "Next.js", category: "Framework", icon: require("./assets/softwareIcons/Nextjs.svg").default },
    { id: 67, name: "Oracle", category: "Tool", icon: require("./assets/softwareIcons/Oracle.svg").default },
    { id: 68, name: "PHP", category: "Programming Language", icon: require("./assets/softwareIcons/PHP.svg").default },
    { id: 44, name: "PostgreSQL", category: "Tool", icon: require("./assets/softwareIcons/PostgresSQL.svg").default },
    { id: 22, name: "PyCharm", category: "Software", icon: require("./assets/softwareIcons/PyCharm.svg").default },
    { id: 10, name: "Python", category: "Programming Language", icon: require("./assets/softwareIcons/Python.svg").default },
    { id: 11, name: "R", category: "Programming Language", icon: require("./assets/softwareIcons/R-.svg").default },
    { id: 36, name: "React", category: "Framework", icon: require("./assets/softwareIcons/React.svg").default },
    { id: 40, name: "React Native", category: "Framework", icon: require("./assets/softwareIcons/React.svg").default },
    { id: 69, name: "RStudio", category: "Software", icon: require("./assets/softwareIcons/RStudio.svg").default },
    { id: 18, name: "Sequential Flow Charts", category: "Tool", icon: require("./assets/softwareIcons/plc.svg").default },
    { id: 13, name: "Siemens TIA Portal", category: "Software", icon: require("./assets/softwareIcons/TIA17.svg").default },
    { id: 71, name: "Slack", category: "Software", icon: require("./assets/softwareIcons/Slack.svg").default },
    { id: 3, name: "SolidWorks", category: "Software", icon: require("./assets/softwareIcons/solidworks.svg").default },
    { id: 70, name: "Sass", category: "Tool", icon: require("./assets/softwareIcons/Sass.svg").default },
    { id: 16, name: "Structured Text", category: "Tool", icon: require("./assets/softwareIcons/plc.svg").default },
    { id: 54, name: "Swift", category: "Programming Language", icon: require("./assets/softwareIcons/Swift.svg").default },
    { id: 46, name: "TensorFlow", category: "Framework", icon: require("./assets/softwareIcons/TensorFlow.svg").default },
    { id: 72, name: "Trello", category: "Software", icon: require("./assets/softwareIcons/Trello.svg").default },
    { id: 73, name: "TypeScript", category: "Programming Language", icon: require("./assets/softwareIcons/TypeScript.svg").default },
    { id: 74, name: "Visual Studio Code", category: "Software", icon: require("./assets/softwareIcons/VSCode.svg").default },
    { id: 75, name: "Xcode", category: "Software", icon: require("./assets/softwareIcons/Xcode.svg").default },
    { id: 78, name: "XML", category: "Tool", icon: require("./assets/softwareIcons/XML.svg").default},
    
];

