// Boot screen assets
export const bootScreenAssets = {
    hdd: require('./bootscreen/HDD.svg').default,
};

// macOS assets
export const macOSAssets = {
    appleBoot: require('./macOS/appleBoot.svg').default,
    underConstruction: require('./macOS/underConstruction.svg').default,
    finder: require('./macOS/finder.svg').default,
    folder: require('./macOS/FolderBlue.svg').default,
    Launchpad: require('./macOS/Launchpad.svg').default,
    AppleTop: require('./macOS/appleTop.svg').default,
};

// Miscellaneous assets
export const miscAssets = {
    me: require('./me.png').default,
    linkedin: require('./misc/linkedin.svg').default,
    mail: require('./misc/mail.svg').default,
    pdf: require('./misc/pdf.svg').default,
};

// Software icons
export const softwareIcons = {
    AWS: require('./softwareIcons/AWS.svg').default,
    Anaconda: require('./softwareIcons/Anaconda.svg').default,
    AndroidStudio: require('./softwareIcons/Android-Studio.svg').default,
    AngularJS: require('./softwareIcons/AngularJS.svg').default,
    Arduino: require('./softwareIcons/Arduino.svg').default,
    Azure: require('./softwareIcons/Azure.svg').default,
    Blender: require('./softwareIcons/Blender.svg').default,
    C: require('./softwareIcons/C.svg').default,
    CMake: require('./softwareIcons/CMake.svg').default,
    CPLEX: require('./softwareIcons/CPLEX-logo.svg').default,
    CPlusPlus: require('./softwareIcons/CPlusPlus.svg').default,
    CSS3: require('./softwareIcons/CSS3.svg').default,
    Docker: require('./softwareIcons/Docker.svg').default,
    Electron: require('./softwareIcons/Electron.svg').default,
    EmbeddedC: require('./softwareIcons/Embedded-C.svg').default,
    Excel: require('./softwareIcons/Excel.svg').default,
    Express: require('./softwareIcons/Express.svg').default,
    Figma: require('./softwareIcons/Figma.svg').default,
    FileZilla: require('./softwareIcons/FileZilla.svg').default,
    Firebase: require('./softwareIcons/Firebase.svg').default,
    Flutter: require('./softwareIcons/Flutter.svg').default,
    Fritzing: require('./softwareIcons/Fritzing.png').default,
    Git: require('./softwareIcons/Git.svg').default,
    GitHub: require('./softwareIcons/GitHub.svg').default,
    GraphQL: require('./softwareIcons/GraphQL.svg').default,
    HTML5: require('./softwareIcons/HTML5.svg').default,
    IntelliJ: require('./softwareIcons/IntelliJ-IDEA.svg').default,
    JSON: require('./softwareIcons/JSON.svg').default,
    Java: require('./softwareIcons/Java.svg').default,
    JavaScript: require('./softwareIcons/JavaScript.svg').default,
    Jira: require('./softwareIcons/Jira.svg').default,
    Jupyter: require('./softwareIcons/Jupyter.svg').default,
    KLayout: require('./softwareIcons/KLayout.png').default,
    Keras: require('./softwareIcons/Keras.svg').default,
    LabVIEW: require('./softwareIcons/LabVIEW.svg').default,
    MATLAB: require('./softwareIcons/MATLAB.svg').default,
    Matplotlib: require('./softwareIcons/Matplotlib.svg').default,
    MongoDB: require('./softwareIcons/MongoDB.svg').default,
    MySQL: require('./softwareIcons/MySQL.svg').default,
    Nextjs: require('./softwareIcons/Nextjs.svg').default,
    Nodejs: require('./softwareIcons/Nodejs.svg').default,
    NumPy: require('./softwareIcons/NumPy.svg').default,
    Oracle: require('./softwareIcons/Oracle.svg').default,
    PHP: require('./softwareIcons/PHP.svg').default,
    PostgresSQL: require('./softwareIcons/PostgresSQL.svg').default,
    PyCharm: require('./softwareIcons/PyCharm.svg').default,
    Python: require('./softwareIcons/Python.svg').default,
    R: require('./softwareIcons/R-.svg').default,
    RStudio: require('./softwareIcons/RStudio.svg').default,
    React: require('./softwareIcons/React.svg').default,
    Sass: require('./softwareIcons/Sass.svg').default,
    Slack: require('./softwareIcons/Slack.svg').default,
    Swift: require('./softwareIcons/Swift.svg').default,
    TIA17: require('./softwareIcons/TIA17.svg').default,
    TensorFlow: require('./softwareIcons/TensorFlow.svg').default,
    Trello: require('./softwareIcons/Trello.svg').default,
    TypeScript: require('./softwareIcons/TypeScript.svg').default,
    VSCode: require('./softwareIcons/VSCode.svg').default,
    XML: require('./softwareIcons/XML.svg').default,
    Xcode: require('./softwareIcons/Xcode.svg').default,
    adobe: require('./softwareIcons/adobe.svg').default,
    autocad: require('./softwareIcons/autocad.svg').default,
    convertcsv: require('./softwareIcons/convertcsv.xlsx').default,
    inventor: require('./softwareIcons/inventor.jpg').default,
    jQuery: require('./softwareIcons/jQuery.svg').default,
    kicad: require('./softwareIcons/kicad.png').default,
    microsoftOffice: require('./softwareIcons/microsoftOffice.svg').default,
    plc: require('./softwareIcons/plc.svg').default,
    solidworks: require('./softwareIcons/solidworks.svg').default,
};

// Windows assets
export const windowsAssets = {
    EngineeringBackground: require('./windows/EngineeringBackground.jpg').default,
    WindowsExplorer: require('./windows/Windows_Explorer.svg').default,
    aptixxEducation: require('./windows/aptixxEducation.svg').default,
    aptixxEnterprise: require('./windows/aptixxEnterprise.svg').default,
    bootLogo: require('./windows/bootLogo.svg').default,
    flextrackhealth: require('./windows/flextrackhealth.svg').default,
    folder: require('./windows/folder.svg').default,
    infinityGreen: require('./windows/infinityGreen.svg').default,
    powerButton: require('./windows/powerButton.svg').default,
    startmenuLogo: require('./windows/startmenuLogo.png').default,
    windowsBackground: require('./windows/windowsBackground.jpeg').default,
};
