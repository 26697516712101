import React from 'react';
import { technologies, Technology } from '../../data';

interface LaunchpadProps {
    
}

const Launchpad: React.FC<LaunchpadProps> = () => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white w-3/4 h-3/4 shadow-lg relative p-4 overflow-x-auto overflow-y-hidden">
               
                <div className="flex space-x-4">
                    {technologies.map(technology => (
                        <div key={technology.id} className="flex-shrink-0 w-32 h-32 flex flex-col items-center">
                            <img src={technology.icon} alt={technology.name} className="w-16 h-16 mb-2" />
                            <p>{technology.name}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Launchpad;
