import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/MacOSSimulation.css';
import { CSProject } from '../data';
import csProjects from '../CSProjectData';
import { macOSAssets, softwareIcons, windowsAssets } from '../assets/assets';
import ProjectWindow from '../components/MacOS/projectWindowMac';
import * as Data from '../data';
import WorkWindow from '../components/MacOS/workExperience';
import Finder from '../components/MacOS/finder';
import Launchpad from '../components/MacOS/launchpad';
import Courses from '../components/MacOS/courseDescMac';
import AppleMenu from '../components/MacOS/appleMenu';
import AboutMe from '../components/MacOS/aboutme';

const folder = macOSAssets.folder;

const MacOSSimulation: React.FC = () => {
    const [activeProject, setActiveProject] = useState<CSProject | null>(null);
    const [startMenuVisible, setStartMenuVisible] = useState<boolean>(false);
    const [activeWork, setActiveWork] = useState<Data.Experience | null>(null);
    const [activeTab, setActiveTab] = useState<string>('projects');
    const [explorerVisible, setExplorerVisible] = useState<boolean>(false);
    const [aboutMeVisible, setAboutMeVisible] = useState<boolean>(false);
    const [activeCourse, setActiveCourse] = useState<Data.Course | null>(null);
    const [activeLaunchpad, setActiveLaunchpad] = useState<boolean>(false);
    const [appleMenuVisible, setAppleMenuVisible] = useState<boolean>(false);

    const handleExplorerClick = (startTab: string) => {
        setStartMenuVisible(false);
        setActiveTab(startTab);
        setExplorerVisible(true);
    };

    const handleCloseExplorer = () => {
        setExplorerVisible(false);
    };

    const handleFolderClick = (project: CSProject) => {
        setActiveProject(project);
    };

    const handleCloseWindow = () => {
        setActiveProject(null);
    };

    const handleAppClick = (work: Data.Experience) => {
        setStartMenuVisible(false);
        setActiveWork(work);
    };

    const handleWorkClose = () => {
        setActiveWork(null);
    };

    const handleStartMenuClick = () => {
        setStartMenuVisible(!activeLaunchpad);
    };

    const handleClickOutside = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!activeLaunchpad) return;
        if ((event.target as Element).closest('#launchpad') === null) {
            setStartMenuVisible(false);
        }
        if ((event.target as Element).closest('#apple-menu') === null) {
            setAppleMenuVisible(false);
        }
    };

    const handleAboutMeClick = () => {
        setAboutMeVisible(true);
        setStartMenuVisible(false);
        setAppleMenuVisible(false);
    };

    const handleCloseAboutMe = () => {
        setAboutMeVisible(false);
    };

    const handleOpenCourse = (course: Data.Course) => {
        setActiveCourse(course);
    };

    const handleCloseCourse = () => {
        setActiveCourse(null);
    };

    const handleAppleMenuClick = () => {
        setAppleMenuVisible(!appleMenuVisible);
    };

    const navigate = useNavigate();

    const handleLogout = () => {
        // Add your logout logic here
        navigate('/');
    };

    return (
        <>
            <div className="h-screen mac-simulation grid grid-cols-7 gap-0 overflow-hidden overscroll-contain" onClick={handleClickOutside}>
                <div className="bg-gray-200 h-4 fixed top-0 w-full flex gap-2 flex-row p-3 items-center mb-2 bg-opacity-20 backdrop-blur-3xl">
                    <div className="flex items-center justify-center w-12 h-full cursor-pointer" onClick={handleAppleMenuClick}>
                        <img src={macOSAssets.AppleTop} alt="Apple Logo" className="w-4 h-4 ml-3 mr-3 flex" />
                    </div>
                    <p className="font-bold text-sm">Finder</p>
                    <p className="text-sm  cursor-pointer" onClick={() => { handleExplorerClick('projects'); }}>My Projects</p>
                    <p className="text-sm  cursor-pointer" onClick={() => { handleExplorerClick('workExp'); }}>Work Experience</p>
                    <p className="text-sm  cursor-pointer" onClick={() => { handleExplorerClick('technologies'); }}>Technologies</p>
                </div>
                <div className="grid grid-flow-col grid-rows-6 gap-0 mb-10 mt-3 col-start-8 " dir='rtl'>
                    {csProjects.map(project => (
                        <div key={project.id} className="mr-4 cursor-pointer flex flex-none flex-col justify-center items-center text-ellipsis tracking-tighter drop-shadow-md" onClick={() => handleFolderClick(project)}>
                            <img src={folder} alt={project.title} className="2xl:w-full w-14 h-14 leading-none row-end-3 flex justify-between" />
                            <p className="text-center text-white line-clamp-1 text-sm overflow-hidden text-ellipsis font-bold drop-shadow-xl">{project.title}</p>
                        </div>
                    ))}
                </div>
                <div className="fixed bottom-0 w-full self-center justify-center flex flex-row items-center p-1">
                    <div className="flex w-6/12 bg-[#D9D9D9] bg-opacity-70 backdrop-blur-2xl gap-3 h-14 rounded-xl mt-3 p-2 flex-none">
                        <div className="flex justify-start h-full" onClick={() => { handleExplorerClick(activeTab); }}>
                            <img src={macOSAssets.finder} alt="Finder" className="h-full" />
                        </div>
                        
                        <div className="flex justify-start h-full">
                            <img src={softwareIcons.VSCode} alt="VSCode" className="h-full bg-white rounded-md p-1" />
                        </div>
                        <div className="flex justify-start h-full ">
                            <img src={softwareIcons.Xcode} alt="XCode" className="item-strech h-full" />
                        </div>
                        <div className="flex justify-start h-full ">
                            <img src={softwareIcons.AndroidStudio} alt="AndroidStudio" className="item-strech h-full" />
                        </div>
                        <div className="flex justify-start h-full ">
                            <img src={softwareIcons.IntelliJ} alt="IntelliJ" className="item-strech h-full" />
                        </div>
                        <div className="flex justify-start h-full ">
                            <img src={softwareIcons.PyCharm} alt="PyCharm" className="item-strech h-full" />
                        </div>
                        <div className="flex justify-start h-full ">
                            <img src={softwareIcons.MATLAB} alt="Matlab" className="item-strech h-full" />
                        </div>
                        <div className="flex justify-start h-full ">
                            <img src={softwareIcons.GitHub} alt="GitHub" className="item-strech h-full" />
                        </div>
                        <div className="flex justify-start h-full ">
                            <img src={softwareIcons.Docker} alt="Docker" className="item-strech h-full" />
                        </div>
                        <div className="flex justify-start h-full ">
                            <img src={softwareIcons.Figma} alt="Figma" className="item-strech h-full bg-black rounded-md p-1" />
                        </div>
                        <div className="flex justify-start h-full ">
                            <img src={softwareIcons.Jira} alt="Jira" className="item-strech h-full" />
                        </div>
                        <div className="flex justify-start h-full ">
                            <img src={softwareIcons.Anaconda} alt="Anaconda" className="item-strech h-full" />
                        </div>
                        <div className="flex justify-start h-full border-r border-r-slate-500 "></div>
                        <div className="flex justify-start h-full cursor-pointer" onClick={() => { handleAppClick(Data.csExperience[2]); }}>
                            <img src={windowsAssets.flextrackhealth} alt="FlexTrack Health" className="item-strech h-full" />
                        </div>
                        <div className="flex justify-start h-full  cursor-pointer" onClick={() => { handleAppClick(Data.csExperience[0]); }}>
                            <img src={windowsAssets.aptixxEducation} alt="Aptixx Education" className="item-strech h-full" />
                        </div>
                        <div className="flex justify-start h-full cursor-pointer" onClick={() => { handleAppClick(Data.csExperience[1]); }}>
                            <img src={windowsAssets.aptixxEnterprise} alt="Aptixx Enterprise" className="item-strech h-full" />
                        </div>
                    </div>
                </div>
                {aboutMeVisible && <AboutMe onClose={handleCloseAboutMe}  />}
                {appleMenuVisible && <AppleMenu onAboutMeClick={handleAboutMeClick} onLogout={handleLogout} />}
                {explorerVisible && <Finder onClose={handleCloseExplorer} startTab={activeTab} onFolderClick={handleFolderClick} onWorkClick={handleAppClick} onCourseClick={handleOpenCourse} />}
                {activeCourse && <Courses course={activeCourse} onClose={handleCloseCourse} />}
                {activeProject && <ProjectWindow project={activeProject} onClose={handleCloseWindow} />}
                {activeWork && <WorkWindow experience={activeWork} onClose={handleWorkClose} />}
            </div>
            <div className='overscroll-contain'>{activeLaunchpad && <Launchpad />}</div>
        </>
    );
};

export default MacOSSimulation;
