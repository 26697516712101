import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

interface DeviceRedirectProps {
    children: React.ReactNode;
}

const DeviceRedirect: React.FC<DeviceRedirectProps> = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleResize = () => {
            const currentPath = location.pathname;
            const isMobile = window.innerWidth < 790;

            console.log('Current Path:', currentPath);
            console.log('Is Mobile:', isMobile);

            if (isMobile) {
                if (currentPath === '/windows') {
                    console.log('Navigating to /android');
                    navigate('/android', { replace: true });
                } else if (currentPath === '/macos') {
                    console.log('Navigating to /ios');
                    navigate('/ios', { replace: true });
                }
            } else {
                if (currentPath === '/android') {
                    console.log('Navigating to /windows');
                    navigate('/windows', { replace: true });
                } else if (currentPath === '/ios') {
                    console.log('Navigating to /macos');
                    navigate('/macos', { replace: true });
                }
            }
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Check on component mount
        handleResize();

        // Clean up event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, [navigate, location.pathname]);

    return <>{window.innerWidth >= 768 ? children : null}</>;
};

export default DeviceRedirect;
