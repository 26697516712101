import { CSProject } from "./data";

export const csProjects: CSProject[] = [
    {
        id: 1,
        title: "Wine Fraud Detection Algorithm",
        description: "A predictive algorithm to classify wines as 'Legit' or 'Fraud' based on chemical properties.",
        locationDuration: "UBC, January 2021 – April 2021",
        overview: "Engineered a predictive Support Vector Machine model using Python for wine classification based on chemical properties.",
        roleResponsibilities: {
            general: "Developed a machine learning model to detect wine fraud.",
            specificTasks: [
                "Engineered a Support Vector Machine model using Python.",
                "Performed data analysis and visualization using Numpy, Pandas, Matplotlib, and Seaborn.",
                "Applied machine learning techniques for reliable wine authentication."
            ]
        },
        technologiesTools: {
            languages: ["Python"],
            frameworks: [],
            tools: ["Numpy", "Pandas", "Matplotlib", "Seaborn"],
            services: []
        },
        challengesFaced: "Handling imbalanced data and ensuring model accuracy.",
        solutionsInnovations: "Implemented data preprocessing techniques and optimized the model parameters.",
        resultsImpact: "Improved the reliability and accuracy of wine authentication.",
        keyLearnings: "Gained expertise in machine learning, data analysis, and model optimization.",
        visualsSupportingMaterial: "See GitHub repository for code and detailed documentation."
    },
    {
        id: 2,
        title: "To-Do List Application",
        description: "A versatile to-do list application developed in Java with multiple viewing options.",
        locationDuration: "UBC, January 2022 – April 2022",
        overview: "Conceived and developed a versatile to-do list application with an enhanced user experience.",
        roleResponsibilities: {
            general: "Designed and developed a to-do list application.",
            specificTasks: [
                "Developed the application in Java.",
                "Created a user-friendly GUI using Swing.",
                "Implemented data structures, object-oriented design, and debugging."
            ]
        },
        technologiesTools: {
            languages: ["Java"],
            frameworks: [],
            tools: ["Swing"],
            services: []
        },
        challengesFaced: "Ensuring efficient task management and user-friendly interface.",
        solutionsInnovations: "Designed multiple viewing options and an intuitive GUI.",
        resultsImpact: "Enhanced user experience and task management efficiency.",
        keyLearnings: "Improved skills in Java, GUI design, and software development.",
        visualsSupportingMaterial: "See GitHub repository for code and detailed documentation."
    },
    {
        id: 3,
        title: "Project Tracker Database",
        description: "A database designed and maintained using Oracle APEX for tracking project progress, budget, and team.",
        locationDuration: "UBC, May 2022 – June 2022",
        overview: "Designed and maintained a database to track project progress, budget, and team.",
        roleResponsibilities: {
            general: "Developed and maintained a project tracking database.",
            specificTasks: [
                "Designed the database using Oracle APEX.",
                "Developed fluency in SQL and familiarity with AWS and Azure DBMS.",
                "Implemented solutions to monitor project status and budget."
            ]
        },
        technologiesTools: {
            languages: ["SQL"],
            frameworks: [],
            tools: ["Oracle APEX"],
            services: ["AWS", "Azure"]
        },
        challengesFaced: "Ensuring accurate and efficient project tracking.",
        solutionsInnovations: "Implemented robust database design and SQL queries.",
        resultsImpact: "Increased project efficiency and control.",
        keyLearnings: "Enhanced skills in database design, SQL, and project management.",
        visualsSupportingMaterial: "See GitHub repository for code and detailed documentation."
    },
    {
        id: 4,
        title: "Twitter Clone",
        description: "A functional clone of Twitter designed and developed using Angular and Firebase.",
        githubLink: "https://github.com/anejaagam/Twitter",
        pdfLink: require("./CS Projects/TwitterClone/PRD.pdf"),
        locationDuration: "UBC, July 2022 – August 2022",
        overview: "Designed and developed a Twitter clone supporting up to 2,000 users.",
        roleResponsibilities: {
            general: "Led the development of a Twitter clone.",
            specificTasks: [
                "Developed the frontend using Angular.",
                "Managed backend services with Firebase.",
                "Implemented project management with Jira and source control with GitHub."
            ]
        },
        technologiesTools: {
            languages: ["JavaScript", "TypeScript"],
            frameworks: ["Angular"],
            tools: ["Figma", "Photoshop"],
            services: ["Firebase"]
        },
        challengesFaced: "Ensuring scalability and user experience.",
        solutionsInnovations: "Implemented robust frontend design and backend services.",
        resultsImpact: "Supported up to 2,000 users with a functional and engaging interface.",
        keyLearnings: "Gained experience in full-stack development and project management.",
        visualsSupportingMaterial: "See GitHub repository for code and detailed documentation."
    },
    {
        id: 5,
        title: "Recumbent E-trike + App",
        description: "Conceptualized and assembled an electrified recumbent trike with a feature-rich app using Flutter and Firebase.",
        image: require("./MechanicalProjects/499/app.jpg"),
        locationDuration: "UBC, September 2022 – March 2023",
        overview: "Developed an electrified recumbent trike and a connected app offering multiple functionalities.",
        roleResponsibilities: {
            general: "Led the design, development, and integration of the e-trike and app.",
            specificTasks: [
                "Designed the trike using SolidWorks.",
                "Developed the app using Flutter and integrated with Firebase.",
                "Managed project workflow with Jira and ensured source control with GitHub."
            ]
        },
        technologiesTools: {
            languages: ["Dart"],
            frameworks: ["Flutter"],
            tools: ["Figma", "Photoshop", "SolidWorks"],
            services: ["Firebase"]
        },
        challengesFaced: "Integrating multiple APIs and ensuring seamless connectivity.",
        solutionsInnovations: "Developed a modular app architecture and integrated various APIs.",
        resultsImpact: "Enhanced user convenience with navigation, audio control, and more.",
        keyLearnings: "Improved skills in app development, API integration, and project management.",
        visualsSupportingMaterial: "See GitHub repository for code and detailed documentation."
    },
    {
        id: 6,
        title: "Reddit Clone",
        description: "Led the development of a Reddit clone in a team environment utilizing HTML, CSS, PHP, MySQL, and Docker.",
        githubLink: "https://github.com/anejaagam/COSC360Project",
        locationDuration: "UBC, June 2023 – July 2023",
        overview: "Developed a Reddit clone with optimal database structure and responsive design.",
        roleResponsibilities: {
            general: "Led the full-stack development of a Reddit clone.",
            specificTasks: [
                "Designed and implemented the backend using PHP and MySQL.",
                "Collaborated on the frontend design using HTML and CSS.",
                "Managed project workflow with Jira and ensured source control with GitHub."
            ]
        },
        technologiesTools: {
            languages: ["PHP", "SQL", "HTML", "CSS"],
            frameworks: [],
            tools: ["Docker"],
            services: []
        },
        challengesFaced: "Ensuring quick data retrieval and scalable storage.",
        solutionsInnovations: "Optimized database structure and implemented efficient queries.",
        resultsImpact: "Enhanced user experience and website performance.",
        keyLearnings: "Improved skills in full-stack development, database management, and collaboration.",
        visualsSupportingMaterial: "See GitHub repository for code and detailed documentation."
    },
    {
        id: 7,
        title: "PatioDate",
        description: "A matchmaking application developed using React Native and AWS Amplify.",
        image: require("./CS Projects/PatioDate.jpeg"),
        locationDuration: "Aptixx Enterprise, September 2023 – May 2024",
        overview: "Spearheaded the full software development lifecycle of PatioDate, ensuring efficient and smooth deployments.",
        roleResponsibilities: {
            general: "Led the development and deployment of a matchmaking application.",
            specificTasks: [
                "Developed the app using React Native and AWS Amplify.",
                "Implemented cloud functions using AWS Lambda and Firebase cloud messaging.",
                "Ensured efficient project management and source control using Jira and GitHub."
            ]
        },
        technologiesTools: {
            languages: ["JavaScript"],
            frameworks: ["React Native"],
            tools: ["Figma"],
            services: ["AWS Amplify", "AWS Lambda", "Firebase"]
        },
        challengesFaced: "Ensuring seamless integration of various cloud services and timely project delivery.",
        solutionsInnovations: "Developed a robust project management plan and optimized deployment pipelines.",
        resultsImpact: "Successfully launched a user-friendly and visually appealing app design.",
        keyLearnings: "Gained experience in full-stack mobile development, cloud services, and project management.",
        visualsSupportingMaterial: "See GitHub repository for code and detailed documentation."
    },
    {
        id: 8,
        title: "FlexTrack App",
        description: "A full-stack mobile iOS health app leveraging computer vision and machine learning.",
        image: require("./CS Projects/FlexTrack.jpeg"),
        locationDuration: "FlexTrack Health, September 2022 - May 2024",
        overview: "Designed, developed, and deployed an innovative computer vision mobile app for physical therapy.",
        roleResponsibilities: {
            general: "Led the design, development, and deployment of the FlexTrack App.",
            specificTasks: [
                "Managed the full product lifecycle from ideation to launch.",
                "Developed the app using Swift and Apple machine learning development SDKs.",
                "Integrated features for tracking fitness and nutritional journeys."
            ]
        },
        technologiesTools: {
            languages: ["Swift"],
            frameworks: [],
            tools: ["Figma"],
            services: ["Apple ML Dev SDK"]
        },
        challengesFaced: "Ensuring high-quality delivery within a tight timeframe.",
        solutionsInnovations: "Adopted Agile methodologies and continuous integration practices.",
        resultsImpact: "Revolutionized physical therapy through AI and machine learning, enhancing user experience.",
        keyLearnings: "Enhanced skills in iOS development, computer vision, and machine learning integration.",
        visualsSupportingMaterial: "See GitHub repository for code and detailed documentation."
    },
    {
        id: 9,
        title: "Finite Element Analysis of a Truss System",
        description: "A detailed finite element analysis of a truss system under various loading conditions to predict displacements, reaction forces, and stresses.",
        pdfLink: require("./MechanicalProjects/492/Course Project 2.pdf"),
        githubLink: "https://github.com/anejaagam/ENGR492-Truss-Analyser",
        images: [],
        locationDuration: "ENGR 492 – Finite Element Methods, University of British Columbia Okanagan, 2023",
        overview: "Utilized finite element methods to analyze structural responses of a truss system, employing the direct stiffness method for computational analysis.",
        roleResponsibilities: {
            general: "Script development, data input, analysis execution, and results interpretation.",
            specificTasks: [
                "Developing Python scripts for finite element analysis.",
                "Inputting and managing structural data and material properties.",
                "Analyzing output data and visualizing displacement and stress."
            ]
        },
        technologiesTools: {
            languages: ["Python"],
            frameworks: [],
            tools: ["NumPy", "Matplotlib"],
            services: []
        },
        challengesFaced: "Accurately modeling the truss system and ensuring correct boundary conditions were applied.",
        solutionsInnovations: "Developed a flexible Python script that adjusted to various inputs to efficiently analyze different scenarios.",
        resultsImpact: "Accurately predicted structural behavior, demonstrating minimal displacements and confirming the truss's structural integrity under normal conditions.",
        keyLearnings: "Gained a deeper understanding of finite element methods and enhanced skills in Python programming and numerical analysis.",
        visualsSupportingMaterial: "Graphs of displacement under load, plots showing the original versus deformed truss structure, and detailed stress distribution across the truss elements."
    }
];

export default csProjects;

