import React from 'react';
import Navigation from './Navigation/navigation';

function App() {
    return (
        <Navigation />
    );
}

export default App;
