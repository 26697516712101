import React from 'react';
import { CSProject } from '../../data';
import { softwareIcons } from '../../assets/assets';
const pdfIcon: string = require('../../assets/misc/pdf.svg').default;

interface ProjectWindowProps {
    project: CSProject;
    onClose: () => void;
}

const ProjectWindow: React.FC<ProjectWindowProps> = ({ project, onClose }) => {
    return (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-0">

            <div className="bg-white w-3/4 h-3/4 shadow-lg relative overflow-auto rounded-md">
                <div className="flex flex-col sticky top-0 bg-white p-4">
                <div className="flex items-center space-x-2 mb-3">
                    <div className="w-3 h-3 bg-red-500 rounded-full cursor-pointer" onClick={onClose}></div>
                    <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
                    <div className="w-3 h-3 bg-green-500 rounded-full"></div>
                </div>
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl">{project.title}</h2>
                    <div className="flex flex-row gap-3">{project.pdfLink && (
                        <div className="mt-4">
                            <a href={project.pdfLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                                <img src={pdfIcon} alt="pdf" className="w-7" />
                            </a>
                        </div>
                    )}
                        {project.githubLink && <div className="mt-4">
                            <a href={project.githubLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                                <img src={softwareIcons.GitHub} alt='Github' className='w-7' />
                            </a>
                        </div>}</div>
                </div>
                </div>
                <div className="p-8">

                    <h3 className="text-lg font-bold">Overview</h3>
                    <p>{project.overview}</p>
                    <h3 className="text-lg font-bold mt-4">Location and Duration</h3>
                    <p>{project.locationDuration}</p>
                    <h3 className="text-lg font-bold mt-4">Role and Responsibilities</h3>
                    <p>{project.roleResponsibilities.general}</p>
                    <ul>
                        {project.roleResponsibilities.specificTasks.map((task, index) => (
                            <li key={index}>- {task}</li>
                        ))}
                    </ul>
                    <h3 className="text-lg font-bold mt-4">Technologies and Tools</h3>
                    <p><strong>Languages:</strong> {project.technologiesTools.languages.join(', ')}</p>
                    <p><strong>Frameworks:</strong> {project.technologiesTools.frameworks.join(', ')}</p>
                    <p><strong>Tools:</strong> {project.technologiesTools.tools.join(', ')}</p>
                    <p><strong>Services:</strong> {project.technologiesTools.services.join(', ')}</p>
                    <h3 className="text-lg font-bold mt-4">Challenges Faced</h3>
                    <p>{project.challengesFaced}</p>
                    <h3 className="text-lg font-bold mt-4">Solutions and Innovations</h3>
                    <p>{project.solutionsInnovations}</p>
                    <h3 className="text-lg font-bold mt-4">Results and Impact</h3>
                    <p>{project.resultsImpact}</p>
                    <h3 className="text-lg font-bold mt-4">Key Learnings</h3>
                    <p>{project.keyLearnings}</p>


                    {project.image && <img src={project.image} alt={project.title} className="w-full h-auto mt-4" />}
                    {project.images && project.images.length > 1 && (
                        <div className="mt-4">
                            <h3 className="text-lg font-bold">Additional Images</h3>
                            <div className="flex space-x-4">
                                {project.images.map((img, index) => (
                                    <img key={index} src={img} alt={`Project`} className="w-1/4 h-auto" />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProjectWindow;
