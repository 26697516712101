import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BootScreen from '../screens/BootScreen';
import WindowsSimulation from '../screens/WindowsSimulation';
import MacOSSimulation from '../screens/MacOSSimulation';
import AndroidSimulation from '../screens/AndroidSimulation';
import WindowsLoadingScreen from '../components/Windows/windowsBoot';
import MacOSBoot from '../components/MacOS/macBoot';
import DeviceRedirect from './DeviceRedirect';
import IosSim from '../screens/iOSSimulation';

const Navigation: React.FC = () => {
    return (
        <Router basename={process.env.PUBLIC_URL}>
            <Routes>
                <Route path="/" element={<BootScreen />} />
                <Route path="/windows" element={
                    <DeviceRedirect>
                        <WindowsSimulation />
                    </DeviceRedirect>
                } />
                <Route path="/macos" element={
                    <DeviceRedirect>
                        <MacOSSimulation />
                    </DeviceRedirect>
                } />
                <Route path="/android" element={
                    <DeviceRedirect>
                        <AndroidSimulation />
                    </DeviceRedirect>
                } />
                <Route path="/ios" element={
                    
                        <IosSim />
                   
                } />
                <Route path="/windows-boot" element={<WindowsLoadingScreen />} />
                <Route path="/macos-boot" element={<MacOSBoot />} />
            </Routes>
        </Router>
    );
};

export default Navigation;
